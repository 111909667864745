import Vue from 'vue'
const vm = new Vue()

// 获取本地版本
// let oldVersion = localStorage.getItem('v3_version');

// 从服务端获取最新的html代码
async function fetchVersion() {
    try {
        const res = await fetch(`/version.json?timestamp=${+new Date()}`);
        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }
        return await res.json();
    } catch (err) {
        console.error('Fetch request failed:', err);

        throw err;
    }
}

let errorCount = 0;
const MAX_ERRORS = 3;
// 判断需不需要更新
async function needUpdate() {
    try {
        const { version } = await fetchVersion();
        // console.log('获取最新版本：', version);

        if (!version) return false; // 如果获取失败，直接返回无需更新

        let result = false;
        console.log('process.env', process.env);

        if (process.env.version && process.env.version !== JSON.parse(version)) {
            result = true;
        }
        errorCount = 0; // 成功时重置错误计数
        return result;
    } catch (err) {
        if (++errorCount >= MAX_ERRORS) {
            clearInterval(updateInterval);
            console.log('暂停查询更新，1分钟后恢复');
            setTimeout(startAutoRefresh, 60000); // 1分钟后重试
        }
        return false;
    }
}

// 递归调用，6秒一次
let updateInterval;
function startAutoRefresh() {
    if (process.env.NODE_ENV === 'development') return; // 开发环境不执行
    clearInterval(updateInterval); // 清除之前的定时器
    updateInterval = setInterval(async () => {
        if (isNoVisible()) return;
        // console.log('检测更新~');
        if (await needUpdate()) {
            clearInterval(updateInterval); // 停止定时器
            vm.$alert('系统已升级，请刷新页面再访问。', '温馨提示！', {
                showClose: false,
                confirmButtonText: '刷新',
                callback: action => {
                    localStorage.setItem('needUpdate', new Date());
                    // window.location.reload(true);
                    forceReload();
                    // location.reload()
                }
            });
        }
    }, 6000);
}

startAutoRefresh()

function isNoVisible() {
    return document.hidden;
}

/**
 * 强制页面重新加载以绕过缓存
 * 
 * 该函数实现了两种方法来达到强制刷新的目的:
 * 1. 通过修改URL的查询参数来触发刷新
 * 2. 使用 location.reload 方法，虽然现代浏览器可能忽略传入的 true 参数
 * 
 * 当前采用的是第一种方案，因为它更可靠地确保了每次调用都会认为是不同的请求，
 * 从而绕过浏览器缓存，实现强制刷新
 */
function forceReload() {
    // 方案1: 修改查询参数触发刷新
    // window.location.href = window.location.href + `?t=${Date.now()}`;
    // window.location.href = window.location.href.split('?')[0] + '?t=' + Date.now();
    // const url = new URL(window.location.href);
    // url.searchParams.set('t', Date.now()); // 添加时间戳参数
    // window.location.href = url.toString();
    // const url = new URL(window.location.href);
    // url.searchParams.set('_t', Date.now());
    // window.location.replace(url.toString());

    // 方案2: 调用 reload（现代浏览器可能忽略 true）
    window.location.reload(true)
}

// 页面可见性监听
if (typeof document !== 'undefined') {
    document.addEventListener('visibilitychange', () => {
        if (!isNoVisible()) {
            setTimeout(() => {
                startAutoRefresh();
            }, 3000); // 延迟3秒再检测更新
        }
    });
}

// 监听storage事件同步版本
window.addEventListener('storage', (e) => {
    if (e.key === 'needUpdate') {
        clearInterval(updateInterval);
        forceReload();
    }
});

// 页面加载后清理参数
// window.addEventListener('load', () => {
//     const url = new URL(window.location.href);
//     if (url.searchParams.has('_t')) {
//         url.searchParams.delete('_t');
//         window.location.replace(url.toString());
//     }
// });