import Vue from 'vue'
const vm = new Vue()

export function convertToChinaNum(num) {
    var arr1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    var arr2 = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿'];//可继续追加更高位转换值
    if (!num || isNaN(num)) {
        return "零";
    }
    var english = num.toString().split("")
    var result = "";
    for (var i = 0; i < english.length; i++) {
        var des_i = english.length - 1 - i;//倒序排列设值
        result = arr2[i] + result;
        var arr1_index = english[des_i];
        result = arr1[arr1_index] + result;
    }
    //将【零千、零百】换成【零】 【十零】换成【十】
    result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');
    //合并中间多个零为一个零
    result = result.replace(/零+/g, '零');
    //将【零亿】换成【亿】【零万】换成【万】
    result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');
    //将【亿万】换成【亿】
    result = result.replace(/亿万/g, '亿');
    //移除末尾的零
    result = result.replace(/零+$/, '')
    //将【零一十】换成【零十】
    //result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
    //将【一十】换成【十】
    result = result.replace(/^一十/g, '十');
    return result;
}

export function getUrlParam(urlStr, urlKey) {
    const url = new URL(urlStr) // 字符串转换成url格式
    const paramsStr = url.search.slice(1) // 获取'?'后面的参数字符串
    const paramsArr = paramsStr.split('&') // 分割'&'字符 获得参数数组
    for (let i = 0; i < paramsArr.length; i++) {
        const tempArr = paramsArr[i].split('=')
        if (tempArr[0] === urlKey) {
            return tempArr[1]
        }
    }
}


export function aegis(params) {
    const userinfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
    if (Object.keys(userinfo)?.length) {
        const integrated = {
            RoleName: userinfo?.nickname ?? '',
            vHeadUrl: userinfo?.avatar ?? '',
            State: userinfo?.muted ? 2 : 1,
            //   clickarea: 'awards2024',
            //   clicktype: type, // 视频播放1/开始创作2/开始使用梦工坊3/社区4/文档5/视频学堂6
            devtype: userinfo?.loginData?.authType ?? 0,
            devstate: userinfo?.loginData?.devStat ?? 0,
            adminid: userinfo?.admin_group_id,
            adminname: userinfo?.admin_group_name ?? '',
            regdate: userinfo?.register_time ?? 0,
            ...params
        }
        console.log('vm.$aegis', vm.$aegis);
        // Playerclick
        vm.$aegis.report({
            msg: 'Playerclick',
            ext1: String(params?.clicktype), // 视频播放1/开始创作2/开始使用梦工坊3/社区4/文档5/视频学堂6
            ext2: userinfo?.muted ? '2' : '1', //账号状态
            ext3: JSON.stringify(integrated), //
            // trace: 'trace',
        })
    } else {
        // 游客
        // Playerclick
        vm.$aegis.report({
            msg: 'Playerclick',
            ext1: String(params?.clicktype), // 视频播放1/开始创作2/开始使用梦工坊3/社区4/文档5/视频学堂6
            ext2: '0', //点击区域
            //   ext3: 0, //账号状态 0 游客
        })
    }
}

export function getChatRequestRoot() {
    const Ticket = {
        // 轻游戏开发者中心测试环境
        'minicreate.': 'https://wxup-hosting-test.game.qq.com',
        // 轻游戏开发者中心正式环境
        'qyx.': 'https://wxup-hosting-pub.game.qq.com',
    };
    const key = Object.keys(Ticket).find(key => location.host.startsWith(key))
    if (key) {
        return Ticket[key]
    }
}

export function getOtherTicket() {
    const Ticket = {
        // 轻游戏开发者中心测试环境
        'minicreate': 'minipre-Ticket',
        // 轻游戏开发者中心正式环境
        'qyx': 'qyxea-Ticket',
    };
    const key = Object.keys(Ticket).find(key => location.host.startsWith(key))
    if (key) {
        console.log(Ticket[key]);
        return Ticket[key]
    }
}

export function getJumpLink() {
    const Ticket = {
        // 轻游戏开发者中心测试环境
        'minicreate': 'https://dev.minicreate.qq.com/#/',
        // 轻游戏开发者中心正式环境
        'qyx': 'https://dev.qyx.qq.com/#/',
    };
    const key = Object.keys(Ticket).find(key => location.host.startsWith(key))
    if (key) {
        console.log(Ticket[key]);
        return Ticket[key]
    }
}

export function confirmCarryVerification() {
    /*
        该正则匹配规则表示: 首先匹配的格式是 xxx = xxx
                           然后[^?|&] 表示匹配的字符中不能含有 ? 或者 &，后面同理
    */
    let pattern = /([^?|&]+)=([^&]+)/ig;
    let url = location.href;
    let result = {};
    url.replace(pattern, ($, $1, $2) => {
        result[$1] = $2;
    })
    if (result.ticket) {
        return {
            ticket: result.ticket,
        }
    }
    if (result.token && result.redirect_url) {
        return {
            token: result.token,
            redirect_url: decodeURIComponent(result.redirect_url),
        }
    } else {
        return false
    }
}


/**
 * 社区 添加图片放大、a标签添加新开页跳转、视频添加播放按钮和隐藏下载
 * @param {*} params 
 */
export function handleRichtextEle(params) {
    const imgArr = document.querySelectorAll('.community_post_container img')
    const imgList = Array.from(imgArr).map(i => i?.src);
    for (let i = 0; i < imgArr.length; i++) {
        if(imgArr[i].alt==='emoji-image') continue
        imgArr[i].title = '图片可点击放大'
        imgArr[i].style.cursor = 'pointer'
        imgArr[i].addEventListener('click', () => {
            vm.$hevueImgPreview({
                multiple: true,
                nowImgIndex: i,
                imgList,
                clickMaskCLose: true,
            })
        })
    }

    // a标签添加新开页面跳转
    const links = document.querySelectorAll('.community_post_container a')
    links.forEach((item) => {
        if (!item.href.includes('#')) {
            item.target = '_blank'
        }
    })

    // 视频去除下载 和添加播放按钮
    const videoArr = document.getElementsByTagName('video');

    for (let i = 0; i < videoArr.length; i++) {
        // videoArr[i].setAttribute('controls', undefined)
        videoArr[i].setAttribute('controlsList', 'nodownload')
        videoArr[i].setAttribute('oncontextmenu', 'return false')
        videoArr[i].style.width = '100%'
        // videoArr[i].style.objectFit='cover'
        //  videoArr[i].style.display = 'none'
        const parent = videoArr[i].parentElement;
        parent.style.position = 'relative'
        const div = document.createElement('div');
        div.className = 'w-video-play-btn';
        div.innerHTML = `<svg width="65" height="65" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" fill="#333" stroke="#333" stroke-width="4" stroke-linejoin="round"/><path d="M20 24V17.0718L26 20.5359L32 24L26 27.4641L20 30.9282V24Z" fill="#FFF" stroke="#FFF" stroke-width="4" stroke-linejoin="round"/></svg>`

        div.addEventListener('click', () => {
            // div.style.display = 'none';
            videoArr[i].play();
            parent.classList.remove('hiddenPlay')
            parent.classList.add('openVideo','videoPlay')
        })

        parent.appendChild(div)

        videoArr[i].addEventListener('pause',()=>{
            // div.style.display = 'block';
            parent.classList.remove('videoPlay')
            parent.classList.add('hiddenPlay')
        })
        videoArr[i].addEventListener('play',()=>{
            parent.classList.remove('hiddenPlay')
            parent.classList.add('videoPlay')
        })
    }

}