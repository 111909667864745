import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import CommunityRouter from './pages/community/router'
/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: "/oauth",
    meta:{
      title:'登录 - 轻游梦工坊官网'
    },
    component: () => import("@/views/oauth/index"),
    hidden: true,
  },
  {
    path: "/redirect", // 专门用于转发的页面
    component: () => import("@/views/redirect.vue"),
    hidden: true,
  },
  {
    path: "/oauth2",
    component: () => import("@/views/oauth/index2"),
    hidden: true,
  },
  {
    path: "/approve",
    component: () => import("@/views/oauth/approve"),
    hidden: true,
  },
  {
    path: "/apply",
    component: () => import("@/views/oauth/apply"),
    hidden: true,
  },
  {
    path: "/chatBox",
    component: () => import("@/views/ChatBox"),
    hidden: true,
  },
  {
    path: "/verifyemail",
    component: () => import("@/views/oauth/verifyemail"),
    hidden: true,
  },
  {
    path: "/success",
    component: () => import("@/views/oauth/success"),
    hidden: true,
  },
  {
    path: "/wx",
    component: () => import("@/views/oauth/wx"),
    hidden: true,
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    hidden: true,
  },
  {
    path: "/401",
    component: () => import("@/views/error-page/401"),
    hidden: true,
  },
];

export const asyncRoutes = [
  {
    path: "/",
    component: () => import("@/layouts/index.vue"),
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: 'Home',
        meta:{
          title:'轻游梦工坊官网'
        },
        component: () => import("@/pages/home/index.vue"),
      },
      {
        path: "/wiki/search",
        name: 'search',
        meta:{
          title:'文档 - 轻游梦工坊'
        },
        component: () => import("@/pages/document/search.vue"),
      },
      {
        path: "/wiki*",
        name: 'wiki',
        meta:{
          title:'文档 - 轻游梦工坊',
        },
        // component: () => import("@/pages/document/index.vue"),
        component: () => import("@/pages/document/NewIndex.vue"),
      },
      // {
      //   path: "/learn/search",
      //   name: 'learnSearch',
      //   meta:{
      //     title:'学堂 - 轻游梦工坊'
      //   },
      //   component: () => import("@/pages/videoSchool/search.vue"),
      // },
      {
        path: "/learn",
        name: 'learn',
        meta:{
          title:'学堂 - 轻游梦工坊'
        },
        component: () => import("@/pages/videoSchool/index.vue"),
      },
      CommunityRouter,
      {
        path: "/learn/videoDetail",
        name: 'videoDetail',
        meta:{
          title:'学堂 - 轻游梦工坊'
        },
        component: () => import("@/pages/videoSchool/videoDetail.vue"),
      },
      {
        path: '/learn/more',
        name: 'more',
        meta: {
          title: '学堂 - 轻游梦工坊'
        },
        component: () => import("@/pages/videoSchool/more.vue")
      }
    ],
  },
  {
    path: "/awards2024",
    name: 'awards2024',
    meta:{
      title:'轻游梦工坊赛区'
    },
    component: () => import("@/pages/awards2024/index.vue"),
  },
];

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: [...constantRoutes, ...asyncRoutes],
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
